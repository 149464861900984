<template>
  <div id="inLedgers">
    <el-dialog
      :title="inLedgersFormTitle"
      width="680px"
      :visible.sync="inLedgersDialogVisible"
      :close-on-click-modal="false"
      @close="inLedgersDialogClose"
    >
      <el-form
        ref="inLedgersFormRef"
        :model="inLedgersForm"
        :rules="inLedgersFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="物料名称" prop="materialName">
              <el-input v-model="inLedgersForm.materialName" placeholder="请输入物料名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="规格/装量" prop="spec">
              <el-input v-model="inLedgersForm.spec" placeholder="请输入规格/装量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="时间" prop="inDate">
              <el-date-picker v-model="inLedgersForm.inDate" placeholder="请选择时间" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批号" prop="batchNum">
              <el-input v-model="inLedgersForm.batchNum" placeholder="请输入批号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="物料编号" prop="materialNum">
              <el-input v-model="inLedgersForm.materialNum" placeholder="请输入物料编号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="检验单号" prop="testNum">
              <el-input v-model="inLedgersForm.testNum" placeholder="请输入检验单号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收入单位" prop="incomeUnit">
              <el-input v-model="inLedgersForm.incomeUnit" placeholder="请输入收入单位" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收入数量" prop="incomeQuantity">
              <el-input v-model="inLedgersForm.incomeQuantity" placeholder="请输入收入数量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="来源去向" prop="sourceWhereabouts">
              <el-input v-model="inLedgersForm.sourceWhereabouts" placeholder="请输入来源去向" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发出量单位" prop="issueUnit">
              <el-input v-model="inLedgersForm.issueUnit" placeholder="请输入发出量单位" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发出量数量" prop="issueQuantity">
              <el-input v-model="inLedgersForm.issueQuantity" placeholder="请输入发出量数量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="结存量单位" prop="balanceUnit">
              <el-input v-model="inLedgersForm.balanceUnit" placeholder="请输入结存量单位" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="结存量数量" prop="balanceQuantity">
              <el-input v-model="inLedgersForm.balanceQuantity" placeholder="请输入结存量数量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发料人" prop="issuedBy">
              <el-input v-model="inLedgersForm.issuedBy" placeholder="请输入发料人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="领用人" prop="collector">
              <el-input v-model="inLedgersForm.collector" placeholder="请输入领用人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input v-model="inLedgersForm.remarks" placeholder="请输入备注" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="inLedgersDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="inLedgersFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="物料名称">
        <el-input v-model="searchForm.materialName" placeholder="请输入物料名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="inLedgersPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="materialName" label="物料名称" />
      <el-table-column prop="spec" label="规格/装量" />
      <el-table-column label="时间">
        <template slot-scope="scope">
          <span v-if="scope.row.inDate">{{ scope.row.inDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="batchNum" label="批号" />
      <el-table-column prop="materialNum" label="物料编号" />
      <el-table-column prop="testNum" label="检验单号" />
      <el-table-column prop="incomeUnit" label="收入单位" />
      <el-table-column prop="incomeQuantity" label="收入数量" />
      <el-table-column prop="sourceWhereabouts" label="来源去向" />
      <el-table-column prop="issueUnit" label="发出量单位" />
      <el-table-column prop="issueQuantity" label="发出量数量" />
      <el-table-column prop="balanceUnit" label="结存量单位" />
      <el-table-column prop="balanceQuantity" label="结存量数量" />
      <el-table-column prop="issuedBy" label="发料人" />
      <el-table-column prop="collector" label="领用人" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="inLedgersPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addInLedgers, deleteInLedgers, updateInLedgers, selectInLedgersInfo, selectInLedgersList } from '@/api/storage/inLedgers'

export default {
  data () {
    return {
      inLedgersDialogVisible: false,
      inLedgersFormTitle: '',
      inLedgersForm: {
        id: '',
        materialName: '',
        spec: '',
        inDate: '',
        batchNum: '',
        materialNum: '',
        testNum: '',
        incomeUnit: '',
        incomeQuantity: '',
        sourceWhereabouts: '',
        issueUnit: '',
        issueQuantity: '',
        balanceUnit: '',
        balanceQuantity: '',
        issuedBy: '',
        collector: '',
        remarks: ''
      },
      inLedgersFormRules: {
        materialName: [{ required: true, message: '物料名称不能为空', trigger: ['blur', 'change']}]
      },
      inLedgersPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        materialName: ''
      }
    }
  },
  created () {
    selectInLedgersList(this.searchForm).then(res => {
      this.inLedgersPage = res
    })
  },
  methods: {
    inLedgersDialogClose () {
      this.$refs.inLedgersFormRef.resetFields()
    },
    inLedgersFormSubmit () {
      if (this.inLedgersFormTitle === '入库分类账详情') {
        this.inLedgersDialogVisible = false
        return
      }
      this.$refs.inLedgersFormRef.validate(async valid => {
        if (valid) {
          if (this.inLedgersFormTitle === '新增入库分类账') {
            await addInLedgers(this.inLedgersForm)
          } else if (this.inLedgersFormTitle === '修改入库分类账') {
            await updateInLedgers(this.inLedgersForm)
          }
          this.inLedgersPage = await selectInLedgersList(this.searchForm)
          this.inLedgersDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.inLedgersFormTitle = '新增入库分类账'
      this.inLedgersDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteInLedgers(row.id)
        if (this.inLedgersPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.inLedgersPage = await selectInLedgersList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.inLedgersFormTitle = '修改入库分类账'
      this.inLedgersDialogVisible = true
      this.selectInLedgersInfoById(row.id)
    },
    handleInfo (index, row) {
      this.inLedgersFormTitle = '入库分类账详情'
      this.inLedgersDialogVisible = true
      this.selectInLedgersInfoById(row.id)
    },
    selectInLedgersInfoById (id) {
      selectInLedgersInfo(id).then(res => {
        this.inLedgersForm.id = res.id
        this.inLedgersForm.materialName = res.materialName
        this.inLedgersForm.spec = res.spec
        this.inLedgersForm.inDate = res.inDate
        this.inLedgersForm.batchNum = res.batchNum
        this.inLedgersForm.materialNum = res.materialNum
        this.inLedgersForm.testNum = res.testNum
        this.inLedgersForm.incomeUnit = res.incomeUnit
        this.inLedgersForm.incomeQuantity = res.incomeQuantity
        this.inLedgersForm.sourceWhereabouts = res.sourceWhereabouts
        this.inLedgersForm.issueUnit = res.issueUnit
        this.inLedgersForm.issueQuantity = res.issueQuantity
        this.inLedgersForm.balanceUnit = res.balanceUnit
        this.inLedgersForm.balanceQuantity = res.balanceQuantity
        this.inLedgersForm.issuedBy = res.issuedBy
        this.inLedgersForm.collector = res.collector
        this.inLedgersForm.remarks = res.remarks
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectInLedgersList(this.searchForm).then(res => {
        this.inLedgersPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectInLedgersList(this.searchForm).then(res => {
        this.inLedgersPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectInLedgersList(this.searchForm).then(res => {
        this.inLedgersPage = res
      })
    }
  }
}
</script>

<style>
</style>
